import { Col, Row } from "react-bootstrap";
import React,{useEffect,useState} from "react";
import {
    TEAM_JSON
} from "../../../../shared/constant/team_data";
import Card from 'react-bootstrap/Card';

import "./Team.css"
import { useFirebase } from "../../../../firebase/Firebase";

function Team() {

    let staticServiceData = Array.from({ length: 5 })

    const [firebaseData, setFirebaseData] = useState([...staticServiceData])
    const firebase = useFirebase()


    useEffect(() => {
        firebase.getTeams((data) => {
          let arr = []
          for (let teamId in data) {
            if (!data[teamId].isDeleted){ 
                arr.push({ ...data[teamId] })
            }
          }
          
        setFirebaseData([...arr])
        })
    
      }, [])

    return (
        <Row className="team-content">
            <Col md={12} className="">
                <span className="heading">Our Core Team</span>
            </Col>
            <Row style={{display:'flex',justifyContent:'space-around'}}>
                {firebaseData.map((item, index) => (
                    item
                     ? <Col key={index} className="d-flex  justify-content-center pt-5 card-content"  md={3} lg={2} sm={6} >
                        <Card>
                            <Card.Body>
                                <Card.Title className="mb-5">
                                    <img src={item.profilePic} alt="profile icon"></img>
                                </Card.Title>
                                <Card.Subtitle className="" style={{display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 3, whiteSpace: 'normal'}}>{item.name}</Card.Subtitle>
                                <Card.Text className="pt-1">
                                    {item.position}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    : <Col key={index} className="d-flex  justify-content-center pt-5 card-content"  md={3} lg={2} sm={6} >
                    <Card>
                        <Card.Body>
                            <Card.Title className="mb-5">
                                <img src={`./icon/profile_icon.webp`} alt="profile icon"
                                ></img>
                            </Card.Title>
                            <Card.Subtitle className="" style={{display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 3, whiteSpace: 'normal'}}>Name</Card.Subtitle>
                            <Card.Text className="pt-1">
                                "Designation"
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                ))}
            </Row>
        </Row>
    );
}

export default Team;
