import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import ABOUT_JSON from "../../../../shared/constant/about_samviddhi_data";
import "./About_samviddhi.css"
import { useFirebase } from "../../../../firebase/Firebase";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function AboutSamviddhi() {

  let staticServiceData = Array.from({ length: 6 })

  const [firebaseData, setFirebaseData] = useState([...staticServiceData])

  const firebase = useFirebase()

  useEffect(() => {
    let storedData = localStorage.getItem('aboutSamviddhiDataFromDatabase')
    if (storedData) {
      setFirebaseData([...JSON.parse(storedData)])
    }

    firebase.getAboutUs((data) => {
      let obj = []
      for (let aboutUsId in data) {
        if (!data[aboutUsId].isDeleted) {
          obj.push({ ...data[aboutUsId], aboutUsId })
        }
      }
      if (obj) {
        setFirebaseData([...obj])
        localStorage.setItem("aboutSamviddhiDataFromDatabase", JSON.stringify([...obj]))
      }
    })
  }, [])

  return (
    <Row className="about-content">
      {firebaseData.map((item, idx) => {
       return (
          item ?
            <Col key={idx}>
              <Col md={12} sm={12} xs className="pt-2 text-center">
                <span className="about-title">{item.title}</span>
              </Col>
              <Col md={12} sm={12} xs className="pt-3" style={{ textAlign: 'justify' }}>
                <div className="about-detail pt-3">{item.description}</div>
              </Col>
            </Col> :
            (
              <SkeletonTheme key={idx}>
                <Row>
                  <Col>
                    <Col md={12} sm={12} xs className=" text-justify heading-text">
                      <Skeleton height={50} width={`100%`} count={1} />
                    </Col>
                    <Col md={12} sm={12}>
                      <Skeleton height={150} width={`100%`} />
                    </Col>
                  </Col>
                </Row>
              </SkeletonTheme>
            )
       )
      })}
    </Row>
  );
}

export default AboutSamviddhi;
