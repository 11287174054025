import React from "react";
import "./Home.css";
import Testimonial from "./section/testimonial/Testimonial";
import Contact from "./section/contactUs/Contact";
import Services from "./section/services/Services";
import Explore from "./section/explore/Explore";
import Footer from "../../shared/component/footer/Footer";
import Team from "../about/section/team/Team";

const Home = () => (
  <>
    <div className="home-page-section pt-70" id="home-page-content">
      <div className="explore-section px-5">
        <Explore />
      </div>
      <div className="services-section px-5" id="service">
        <Services />
      </div>
      <div className="testimonials-section px-5" id="testimonial">
        <Testimonial />
      </div>
      <div className="contact-us-section px-5" id="contact_us">
        <Contact />
      </div>

    </div>
  </>

);

export default Home;
