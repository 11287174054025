import React, { useEffect,useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./Footer.css";
import "../../../App.css";
import { NavLink, useLocation } from "react-router-dom";
import { useFirebase } from "../../../firebase/Firebase";

const Footer = () => {

  let staticServiceData = Array.from({ length: 5 })

  const [firebaseDataService, setFirebaseDataService] = useState([...staticServiceData])
  const [firebaseDataSocialIcons, setFirebaseDataSocialIcons] = useState([...staticServiceData])

  const firebase = useFirebase()

  const location = useLocation();


  useEffect(() => {
    const isHomeOrAboutPage = location.pathname === "/" || location.pathname === "/about";
    if (isHomeOrAboutPage === false) {
      const tag = document.querySelector(".footer");
      if (tag) tag.style.display = "none";
    } else {
      const tag = document.querySelector(".footer");
      if (tag) tag.style.display = "block"; // Or whatever appropriate value you want
    }
  })

  useEffect(() => {
    firebase.getServices((data) => {
      let arr = []
      for (let serviceId in data) {
        if (!data[serviceId].isDeleted){ 
          arr.push({ ...data[serviceId] })
        }
      }
      
    setFirebaseDataService([...arr])
    })

  }, [])

  useEffect(() => {
    firebase.getContactUs((data) => {
      let arr = []
      for (let contactUsId in data) {
        if (!data[contactUsId].isDeleted){ 
          arr.push({ ...data[contactUsId] })

        }
      }
      
    setFirebaseDataSocialIcons([...arr])
    })

  }, [])

  const handleHomeBtnClick = () => {
    const homePageFirstTag = document.getElementById('home-page-content')
    homePageFirstTag.scrollIntoView(true)
  }



  return (
    <div className="footer px-5">
      <div className="info-content responsive-width">
        <Row className="p-0 m-0" style={{ display: 'flex', justifyContent: '' }}>
          <Col md={6} lg={6} sm={12} xl={4} className="contacts ">
            <div className="samviddhi-logo_text">
              <NavLink to="/" onClick={handleHomeBtnClick}>
                {/* <img alt="logo" style={{ width: "40px" }} src={"../logo64.png"} /> */}
                <img alt="logo" style={{ width: "50%" }} src={"./icon/lotion-10124.png"} />

              </NavLink>
            </div>
            <div id="email-contact" className="pt-4 px-2">
              <a href="mailto:contact@samviddhi.com" className="email text-hover">
                <i className="bi bi-envelope-fill pe-2"></i>
                contact@samviddhi.com
              </a>
            </div>
            <div className="social-app-icons pt-4 ">
            {firebaseDataSocialIcons.map((item,index)=>{
              if(item){
                let name = item.title
                return (
                  <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={`icon ${item.title}`}>
                    <div className="tooltip">
                      {name[0].toUpperCase() + name.slice(1)}
                    </div>
                    <span>
                      <i className={item.className}></i>
                    </span>
                  </div>
                </a>
                )
              }
            })}
            </div>

          </Col>
        
          <Col md={6} lg={6} sm={12} xl={6} className="services ">
            <span id="heading" className="pb-2">Services</span>
            <div className="list-items">
              <div className="pe-3" style={{ overflow: '', whiteSpace: 'nowrap' }}>
                <ul style={{ paddingLeft: '1rem' }}>
                {/* {firebaseDataService.map((data,index)=>{
                  if(data && index<4){
                  return (
                    <li key={index}>{data.title}</li>
                  )
                  }
                })} */}
                  <li>Mobile App Development</li>
                  <li>Web App Development</li>
                  <li>DevOps Consultancy</li>
                  
                </ul>
              </div>
              <div className="" >
                <ul style={{ paddingLeft: '1rem' }}>
                {/* {firebaseDataService.map((data,index)=>{
                  if(data && index>=4){
                  return (
                    <li key={index}>{data.title}</li>
                  )
                  }
                })} */}
                <li>Data Science</li>
                <li>Computer Vision and Image Processing</li>
                  <li>Geo-Spatial Analysis</li>
                </ul>
              </div>
            </div>

          </Col>
          {/* <Col md={6} lg={2} sm={12} className="left-services">
        <span id="heading" className="pb-2">.</span>

          <div className="">
            
          </div>
        </Col> */}
        </Row>
      </div>
      <Row className="p-0 m-0 pt-4">
        <Col xs sm className="text-center py-2">
          <div className="dash pb-3"></div>
          <span className="copy-right">
          Copyright© 2024 SamvidDhi Intelligent System LLP
          </span>
        </Col>
      </Row>
    </div>
  )

};

export default Footer;
