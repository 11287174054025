import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";
import FOLLOW_US_JSON from "../../../../shared/constant/contact_data";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import emailjs from "@emailjs/browser"
import "./Contact.css"

function Contact() {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  const mobileNoPattern = /^[6-9]\d{9}$/;

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [message, setMessage] = useState("")
  const [companyName, setCompanyName] = useState("")

  const [companyPlaceHolder, setCompanyPlaceHolder] = useState("")
  const [numberPlaceHolder, setNumberPlaceHolder] = useState("")
  const [messageLetterCount, setMessageLetterCount] = useState(0)

  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false);

  const [validated, setValidated] = useState(false);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const emailElement = document.querySelector("input[name=email]")
    if (emailRegex.test(emailElement.value)) {
      emailElement.setCustomValidity("")
    }
    else {
      emailElement.setCustomValidity("Please enter a valid email")
    }
  };

  const handlePhoneNoChange = (e) => {
    setNumber(e.target.value)
    const phoneNumber = document.querySelector("input[name=phoneNo]");
    if (mobileNoPattern.test(phoneNumber.value)) {
      phoneNumber.setCustomValidity("");
    } else {
      phoneNumber.setCustomValidity(
        "Invalid Mobile number (e.g., 010-1234-5678)"
      );
    }
  };

  const handleMessageChange = (e) => {
    let text = e.target.value
    if (text.length <= 500) {
      setMessage(text)
      setMessageLetterCount(text.length)
      e.target.setCustomValidity("");
    }
    else {
      e.target.setCustomValidity("Message text limit exceeds.");

    }
  }



  useEffect(() => {
    emailjs.init('sbnTxd7cU-3b-pcUH')
  }, [])



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const serviceId = "service_pxp7ooc";
    // const serviceId = "service_fm77uk2";
    const templateId = "template_vbnjngj";
    // const templateId = "template_2xxwmkn"
    const form = e.currentTarget;
    

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    else {
      try {
        setLoading(false);
        await emailjs.send(serviceId, templateId, {
          name: name,
          phone_number: number,
          message: message,
          user_email: email,
          company_name: companyName,
        });
         setName("");
            setEmail("");
            setNumber("");
            setMessage("");
            setCompanyName("");
        setShow(true)
        form.reset()
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(true);
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    const setPlaceholder = () => {
      if (window.innerWidth < 600) {
        setCompanyPlaceHolder('Company');
        setNumberPlaceHolder("Ph. Number")
      } else {
        setCompanyPlaceHolder('Business/Company Name');
        setNumberPlaceHolder("Phone Number")
      }
    };

    setPlaceholder();

    window.addEventListener('resize', setPlaceholder);

    return () => {
      window.removeEventListener('resize', setPlaceholder);
    };

  })

  return (
    <Row className="contact-content">
      <Alert show={show} variant="success" className="email-sent-alert">
        <Alert.Heading>Alert</Alert.Heading>
        <p className="alert-text">We have received your message! We will get back to you soon!!</p>
        <div className="d-flex justify-content-end">
          <Button onClick={() => {
            setShow(false)
            // setName("");
            // setEmail("");
            // setNumber("");
            // setMessage("");
            // setCompanyName("");
          }} variant="outline-success">
            Ok
          </Button>
        </div>
      </Alert>
      <Col className="form-and-heading">
        <div className="text-area pb-4">
          <span id="heading" className="pt-5">
            Get in Touch
          </span>

          <span id="sub-heading" className="pt-4">
            Let's level up your business together
          </span>
        </div>
        <Form className="pb-4" noValidate validated={validated} name="contact_us_form" onSubmit={handleSubmit}>
          <Row>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control
                  required
                  value={name}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full Name"
                  pattern="[A-Za-z ]+"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid name containing only letters.
                </Form.Control.Feedback>
              </Form.Group>

            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicCompanyName">
                <Form.Control required type="text" value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder={companyPlaceHolder}
                  style={{ padding: '.375rem 0.75rem' }}
                  pattern="[A-Za-z ]+" />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid company name containing only letters.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control required type="email" value={email}
                  onChange={handleEmailChange}
                  placeholder="E-mail"
                  style={{ padding: '.375rem 0.75rem' }}
                  name="email"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter a valid E-mail Id
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicNumber">
                <Form.Control required type="number" value={number}
                  onChange={handlePhoneNoChange}
                  placeholder={numberPlaceHolder}
                  name="phoneNo"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter a valid Phone Number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>




          <Form.Group className="mb-3" controlId="formBasicMessage">
            <Form.Control
              required
              type="text"
              value={message}
              onChange={(e) =>
                handleMessageChange(e)}
              as="textarea"
              rows={3}
              name="messageBox"
              placeholder="Type your message/query here" />
            <Form.Text>{messageLetterCount}/500</Form.Text>
          </Form.Group>

          <Button variant="" id="submit-btn" type="submit" disabled={loading && name && email && number && message && companyName ? false : true} >
            Send
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

export default Contact;
