import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  SERVICE_TITLE_JSON,
} from "../../../../shared/constant/services_data";
import Card from 'react-bootstrap/Card';
import "./Services.css"
import { useFirebase } from "../../../../firebase/Firebase";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Services() {
  let staticServiceData = Array.from({ length: 6 })

  const [firebaseData, setFirebaseData] = useState([...staticServiceData])
  const firebase = useFirebase()

  useEffect(() => {
    let storedData = localStorage.getItem('serviceSectionDataFromDatabase')
    if (storedData) {
      setFirebaseData([...JSON.parse(storedData)])
    }

    firebase.getServices((data) => {
      let obj = []
      for (let serviceId in data) {
        if (!data[serviceId].isDeleted){
          obj.push({ ...data[serviceId] })
        }
      }
      if (obj) {
        setFirebaseData([...obj])
        localStorage.setItem("serviceSectionDataFromDatabase", JSON.stringify([...obj]))
      }
    })


    // firebase.getServices((data) => {
    //   let obj = []
    //   for (let serviceId in data) {
    //     obj.push({ ...data[serviceId] })
    //   }
    //   if (obj) {
    //     setFirebaseData([...obj])
    //   }
    // })
  }, [])
  
  return (
    <Row className="services-content">
      <Col md={12} className="">
        <span className="our-services">Our Services</span>
        <div className="pt-4 text-justify" id="description">
          {SERVICE_TITLE_JSON.description}
        </div>
      </Col>
      {firebaseData.map((item, index) => (
        item
          ? <Col key={index} className="d-flex  justify-content-center pt-5 " md={4} lg={4} sm={6}>
            <Card style={{ borderRadius:'10px' }}>
              <div className="position-relative">
                <img src={item.icon} className="card-icon position-absolute top-50 translate-middle" ></img>
              </div>
              <Card.Body>
                <Card.Title className="">{item.title}</Card.Title>
                <Card.Text className="pt-2">
                  {item.description}
                </Card.Text>
                <Card.Link href="#">Learn more</Card.Link>
              </Card.Body>
            </Card>
          </Col>
















          : <SkeletonTheme key={index}>
          <Col className="d-flex  justify-content-center pt-5 " md={4} lg={4} sm={6}>
            <Card style={{ borderRadius:'10px',width:'100%' }}>
              {/* <div className="position-relative">
                <Skeleton className=" card-icon position-absolute top-50 translate-middle" height={20} width={`100%`} count={1} />
              </div> */}
              <Card.Body>
              <Skeleton height={260} width={`100%`} count={1} />
              </Card.Body>
            </Card>
          </Col>
          </SkeletonTheme> 
      ))}
    </Row>
  );
}

export default Services;
