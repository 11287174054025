import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { WORK_SECTION_JSON } from "../../../../shared/constant/work_section_data";
import Card from "react-bootstrap/Card";
import "./Work-section.css"
import { useFirebase } from "../../../../firebase/Firebase";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function WorkSection() {

  let staticServiceData = Array.from({ length: 4 })


  const [firebaseData, setFirebaseData] = useState([...staticServiceData])
  let firebase = useFirebase()

  useEffect(() => {
    let storedData = localStorage.getItem('workSectionDataFromDatabase')
    if(storedData){
      setFirebaseData([...JSON.parse(storedData)])
    }

    firebase.getWhyUs((data) => {
      let arr = []
      for (let id in data) {
        if (!data[id].isDeleted) {
        arr.push({ ...data[id], id })
        }
      }
      setFirebaseData([...arr])
      localStorage.setItem("workSectionDataFromDatabase",JSON.stringify([...arr]))
    })
   
  }, [])
  return (
    <>
      <Row className="work-section-content">
        <Col md={12} className=" text-center">
          <span className="why-us-heading">Why Work With Us</span>
          <div className="pt-4 text-justify" id="description">
            Partnering with us offers a multitude of benefits, including access to expertise and innovation, customized solutions tailored to your unique needs, stringent quality assurance processes ensuring robust and reliable software, timely delivery without compromising quality, collaborative partnerships characterized by open communication and transparency, and ongoing support to ensure your software continues to perform optimally. With us, you'll gain a trusted ally dedicated to empowering your business with cutting-edge solutions that drive tangible results, facilitating the achievement of your objectives and ensuring long-term success.
          </div>
        </Col>
        {firebaseData.map((item, index) => (
          item
            ? <React.Fragment key={index + 0}>
              <Row className="info-cards small-screen" key={index + 1}>
                <>
                  <Col md={2} lg={2} sm={2} style={{ minWidth: '100px' }} className="pt-4 px-4">
                    <img src={item.icon} alt="About Icon"
                      style={{ width: '76px', height: '76px' }}></img>
                  </Col>
                  <Col key={index} md={10} lg={10} sm={10} className="py-4 px-4">
                    <div id="info-heading">
                      {item.title}
                    </div>
                    <div id="info-description" className="pt-4">
                      {item.description}
                    </div>
                  </Col>
                </>
              </Row>



              <Row className="info-cards mid-screen" key={index + 2}>
                {index % 2 == 0 ?
                  (
                    <>
                      <Col md={2} lg={2} sm={12} style={{ minWidth: '100px' }} className="pt-4 px-4">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={item.icon} alt="About Icon"
                            style={{ width: '76px', height: '76px' }}></img>
                          <div id="info-heading" className="text-left ps-4">
                            {item.title}
                          </div>
                        </div>

                      </Col>
                      <Col key={index} md={10} lg={10} sm={12} className="py-4 px-4">

                        <div id="info-description">
                          {item.description}
                        </div>
                      </Col>
                    </>

                  )

                  :
                  (
                    <>
                      <Col md={2} lg={2} sm={12} style={{ minWidth: '100px' }} className="pt-4 px-4">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <div id="info-heading" className="text-left pe-4">
                            {item.title}
                          </div>
                          <img className="" src={item.icon} alt="About Icon"
                            style={{ width: '76px', height: '76px' }}>
                          </img>
                        </div>

                      </Col>
                      <Col key={index} md={10} lg={10} sm={12} className="py-4 px-4">

                        <div id="info-description">
                          {item.description}
                        </div>
                      </Col>
                    </>
                  )
                }
              </Row>


              <Row className="info-cards large-screen" key={index + 3}>
                {index % 2 == 0 ?
                  (
                    <>
                      <Col md={2} lg={2} sm={2} style={{ minWidth: '100px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <img src={item.icon} alt="About Icon"
                          style={{ width: '100px', height: '100px' }}></img>
                      </Col>
                      <Col key={index} md={10} lg={10} sm={10}>
                        <div id="info-heading" className="text-left pb-3">
                          {item.title}
                        </div>
                        <div id="info-description">
                          {item.description}
                        </div>
                      </Col>
                    </>

                  )

                  :
                  (
                    <>
                      <Col key={index} md={10} lg={10} sm={10}>
                        <div id="info-heading" className="text-right pb-3">
                          {item.title}
                        </div>
                        <div id="info-description" className="text-right">
                          {item.description}
                        </div>
                      </Col>
                      <Col md={2} lg={2} sm={2} style={{ minWidth: '100px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <img src={item.icon} alt="About Icon" style={{ width: '100px', height: '100px' }}></img>
                      </Col>
                    </>

                  )
                }
              </Row>
            </React.Fragment>
            : <React.Fragment key={index + 0}>
              <Row className="info-cards small-screen" key={index + 1}>
                <>
                  <Col md={2} lg={2} sm={2} style={{ minWidth: '100px' }} className="pt-4 px-4">
                    <Skeleton height={76} width={76} count={1} />

                    {/* <img src={"./icon/why_work_with_us/who_we_are.png"} alt="About Icon"
                      style={{ width: '76px', height: '76px' }}></img> */}
                  </Col>
                  <Col key={index} md={10} lg={10} sm={10} className="py-4 px-4">
                    <div id="info-heading">
                      {/* "Who we are?" */}
                      <Skeleton height={82} width={`100%`} count={1} />

                    </div>
                    <div id="info-description" className="pt-4">
                      {/* "The team behind the name is a group of highly motivated and hard working individuals, determined to solve the challenging problems and have a promise to work towards the betterment of society with help of technology. Our leadership has a decent experience in research and development in diverse domains and applications of data sciences. We also have some external advisors for our in-house products and R&D.", */}
                      <Skeleton height={220} width={`100%`} count={1} />

                    </div>
                  </Col>
                </>
              </Row>



              <Row className="info-cards mid-screen" key={index + 2}>
                {index % 2 == 0 ?
                  (
                    <>
                      <Col md={2} lg={2} sm={12} style={{ minWidth: '100px' }} className="pt-4 px-4">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Skeleton height={76} width={76} count={1} />

                          {/* <img src={"./icon/why_work_with_us/who_we_are.png"} alt="About Icon"
                            style={{ width: '76px', height: '76px' }}></img> */}
                          <div id="info-heading" className="text-left ps-4">
                            {/* "Who we are?" */}
                            {/* <Skeleton height={82} width={`100%`} count={1} /> */}
                          </div>
                        </div>
                      </Col>
                      <Col key={index} md={10} lg={10} sm={12} className="py-4 px-4">
                        <div id="info-description">
                          {/* "The team behind the name is a group of highly motivated and hard working individuals, determined to solve the challenging problems and have a promise to work towards the betterment of society with help of technology. Our leadership has a decent experience in research and development in diverse domains and applications of data sciences. We also have some external advisors for our in-house products and R&D.", */}
                          <Skeleton height={150} width={`100%`} count={1} />
                        </div>
                      </Col>
                    </>

                  )

                  :
                  (
                    <>
                      <Col md={2} lg={2} sm={12} style={{ minWidth: '100px' }} className="pt-4 px-4">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <div id="info-heading" className="text-left pe-4">
                            {/* <Skeleton height={82} width={`100%`} count={1} /> */}

                            {/* {"Who we are?"} */}
                          </div>
                          <Skeleton height={76} width={76} count={1} />

                          {/* <img className="" src={"./icon/why_work_with_us/who_we_are.png"} alt="About Icon"
                            style={{ width: '76px', height: '76px' }}>
                          </img> */}
                        </div>

                      </Col>
                      <Col key={index} md={10} lg={10} sm={12} className="py-4 px-4">

                        <div id="info-description">
                          {/* "The team behind the name is a group of highly motivated and hard working individuals, determined to solve the challenging problems and have a promise to work towards the betterment of society with help of technology. Our leadership has a decent experience in research and development in diverse domains and applications of data sciences. We also have some external advisors for our in-house products and R&D.", */}
                          <Skeleton height={150} width={`100%`} count={1} />

                        </div>
                      </Col>
                    </>
                  )
                }
              </Row>


              <Row className="info-cards large-screen" key={index + 3}>
                {index % 2 == 0 ?
                  (
                    <>
                      <Col md={2} lg={2} sm={2} style={{ minWidth: '100px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Skeleton height={100} width={100} count={1} />

                        {/* <img src={"./icon/why_work_with_us/who_we_are.png"} alt="About Icon"
                          style={{ width: '100px', height: '100px' }}></img> */}
                      </Col>
                      <Col key={index} md={10} lg={10} sm={10}>
                        <div id="info-heading" className="text-left pb-3">
                          {/* {"Who we are?"} */}
                          <Skeleton height={54} width={`100%`} count={1} />

                        </div>
                        <div id="info-description">
                          {/* "The team behind the name is a group of highly motivated and hard working individuals, determined to solve the challenging problems and have a promise to work towards the betterment of society with help of technology. Our leadership has a decent experience in research and development in diverse domains and applications of data sciences. We also have some external advisors for our in-house products and R&D.", */}
                          <Skeleton height={82} width={`100%`} count={1} />

                        </div>
                      </Col>
                    </>

                  )

                  :
                  (
                    <>
                      <Col key={index} md={10} lg={10} sm={10}>
                        <div id="info-heading" className="text-right pb-3">
                          {/* {"Who we are?"} */}
                          <Skeleton height={54} width={`100%`} count={1} />

                        </div>
                        <div id="info-description" className="text-right">
                          {/* "The team behind the name is a group of highly motivated and hard working individuals, determined to solve the challenging problems and have a promise to work towards the betterment of society with help of technology. Our leadership has a decent experience in research and development in diverse domains and applications of data sciences. We also have some external advisors for our in-house products and R&D.", */}
                          <Skeleton height={82} width={`100%`} count={1} />

                        </div>
                      </Col>
                      <Col md={2} lg={2} sm={2} style={{ minWidth: '100px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        {/* <img src={"./icon/why_work_with_us/who_we_are.png"} alt="About Icon" style={{ width: '100px', height: '100px' }}></img> */}
                        <Skeleton height={100} width={100} count={1} />

                      </Col>
                    </>

                  )
                }
              </Row>
            </React.Fragment>




        ))}
      </Row>
    </>

  );
}

export default WorkSection;
