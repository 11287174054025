import React from 'react';
import { useParams } from 'react-router-dom';
import "./JobDescription.css";
import { CAREER_DATA } from "./../../shared/constant/career_data";
import { NavLink } from "react-router-dom";

function JobDescription() {
  const { id } = useParams();

  return (
    <div className="JD_container">      
      {CAREER_DATA[0].data.filter(item => item.id == id ).map((item, index) => (
        <div key={index} className="work-section">
          <h2 className='work-section-title'>{item.title}</h2>
            <div className='JD_content'>
              <h3>{item.requirements.title}</h3>
              <p>{item.requirements.description}</p>
              <h3>{item.responsibilitys.title}</h3>
              <p>{item.responsibilitys.description}</p>
              <h3>{item.offers.title}</h3>
              <p>{item.offers.description}</p>
              <NavLink to={`/career/${item.id}/apply`} className="read_more-btn">APPLY</NavLink> 
            </div>
        </div>        
      ))} 
    </div>
  );
}
  
export default JobDescription;
