import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import "./Explore.css"
import { useFirebase } from "../../../../firebase/Firebase";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Explore() {
  let staticServiceData = Array.from({ length: 1 })

  const [firebaseData, setFirebaseData] = useState([...staticServiceData])
  const firebase = useFirebase()

  useEffect(() => {
    let storedData = localStorage.getItem('heroSectionDataFromDatabase')
    if (storedData) {
      setFirebaseData([...JSON.parse(storedData)])
    }

    firebase.getHeroSection((data) => {
      let obj = []
      for (let id in data) {
        obj.push({ ...data[id] })
      }
      if (obj) {
        setFirebaseData([...obj])
        localStorage.setItem("heroSectionDataFromDatabase", JSON.stringify([...obj]))
      }
    })

  }, [])

  return (
    <Row className="explore-content">
      {firebaseData[0] ? <>
        <Col md={12} lg={6} sm={12}>
          <Col md={12} sm={12} xs className=" text-justify heading-text">
            <span className="info-heading">
              {firebaseData[0] && firebaseData[0].heading}
              {/* Think, imagine and accelerate exponentially... */}
            </span>
            <span className="info-details">
              {/* We offer the great IT services with best and most suitable recent technologies. */}
              {firebaseData[0] && firebaseData[0].subHeading}
            </span>

          </Col>
          <button className="mt-3" id="get-started-btn">Get Started</button>
        </Col>

        <Col md={12} lg={6} sm={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className="illustration" src={firebaseData[0] && firebaseData[0].illustration} alt="explore" />
        </Col>
      </>

        :
        (
          <SkeletonTheme>
            <Row>
              <Col md={12} lg={6} sm={12}>
                <Col md={12} sm={12} xs className=" text-justify heading-text">
                  {/* <Skeleton height={246} width={`100%`} count={1} /> */}

                  <Skeleton height={82} width={`100%`} count={1} />
                  <Skeleton height={82} width={`100%`} count={1} />
                  <Skeleton height={82} width={`100%`} count={1} />

                  <div style={{ marginTop: '20px' }}>
                    <Skeleton height={48} width={`100%`} count={1} />
                  </div>
                </Col>
              </Col>

              <Col md={12} lg={6} sm={12}>
                <Skeleton height={270} width={`100%`} />
              </Col>
            </Row>

          </SkeletonTheme>
        )}

    </Row>
  );
}

export default Explore;
