import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState, Suspense, lazy } from "react";
import ABOUT_INFO_JSON from "../../../../shared/constant/about_info_data";
import "./About_info.css"
import { useFirebase } from "../../../../firebase/Firebase";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


function AboutInfo() {

  let staticServiceData = Array.from({ length: 6 })

  const [firebaseData, setFirebaseData] = useState([...staticServiceData])

  const firebase = useFirebase()

  const Comp = () => (
    <>
      <Col>
        <Col md={12} sm={12} xs className="pt-2">
          <span className="info-heading">{firebaseData[0].title}</span>
        </Col>
        <Col md={12} sm={12} xs className="pt-3" style={{ textAlign: 'justify' }}>
          <span className="info-details">{firebaseData[0].description}</span>
        </Col>
      </Col>
      <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img className="about-image" src={firebaseData[0].icon} alt="About" />
      </Col>
    </>
  );

  useEffect(() => {
    let storedData = localStorage.getItem('aboutInfoDataFromDatabse')
    if (storedData) {
      setFirebaseData([...JSON.parse(storedData)])
    }

    firebase.getOurStory((data) => {
      let obj = []
      for (let aboutUsId in data) {
        if (!data[aboutUsId].isDeleted) {
          obj.push({ ...data[aboutUsId], aboutUsId })
        }
      }
      if (obj) {
        setFirebaseData([...obj])
        localStorage.setItem("aboutInfoDataFromDatabse", JSON.stringify(obj))
      }
    })
  }, [])

  return (
    <Row className="info-content">
      {
        firebaseData.map((item, idx) => {
          return (
            item ?
              <Row className="pb-5" key={idx}>
                <Col>
                  <Col md={12} sm={12} xs className="pt-2" >
                    <span className="info-heading"
                    >
                      {item.title}</span>
                  </Col>
                  <Col md={12} sm={12} xs className="pt-3" style={{ textAlign: 'justify' }} >
                    <span className="info-details">{item.description}</span>
                  </Col>
                </Col>

                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img className="about-image" src={item.icon} alt="About" />
                </Col>
              </Row> :
              <SkeletonTheme key={idx}>
                <Row className="pt-2">
                  <Col md={12} lg={6} sm={12}>
                    <Col md={12} sm={12} xs className=" text-justify heading-text">
                      <Skeleton height={90} width={`100%`} count={1} />
                      <div className="pt-3">
                        <Skeleton height={160} width={`100%`} count={1} />
                      </div>
                    </Col>
                  </Col>
                  <Col md={12} lg={6} sm={12}>
                    <Skeleton height={300} width={`100%`} />
                  </Col>
                </Row>
              </SkeletonTheme>
          )
        })
      }
      {/* {firebaseData[0] ? 
      <>
        <Col>
          <Col md={12} sm={12} xs className="pt-2" >
            <span className="info-heading"
            >
              {firebaseData[0] ? firebaseData[0].title : ""}</span>
          </Col>
          <Col md={12} sm={12} xs className="pt-3" style={{ textAlign: 'justify' }} >
            <span className="info-details">{firebaseData[0] ? firebaseData[0].description : ""}</span>
          </Col>
        </Col>

        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className="about-image" src={firebaseData[0] ? (firebaseData[0].icon) : ""} alt="About" />
        </Col>
      </>
        : 
        <SkeletonTheme>
          <Row className="pt-2">
            <Col md={12} lg={6} sm={12}>
              <Col md={12} sm={12} xs className=" text-justify heading-text">
                <Skeleton height={90} width={`100%`} count={1} />
                <div className="pt-3">
                  <Skeleton height={160} width={`100%`} count={1} />
                </div>
              </Col>
            </Col>
            <Col md={12} lg={6} sm={12}>
              <Skeleton height={300} width={`100%`} />
            </Col>
          </Row>
        </SkeletonTheme>
      } */}
    </Row>
  );
}

export default AboutInfo;
