import "./App.css";
import Navbar from "./shared/component/Navbar/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/about/About";
import Apply from "./pages/apply/Apply";
import Career from "./pages/career/Career";
import Home from "./pages/home/Home";
import JobDescription from "./pages/jobDescription/JobDescription"
import Footer from "./shared/component/footer/Footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-loading-skeleton/dist/skeleton.css'

import Service from "./pages/service/Service";
import Testimonial from "./pages/testimonials/Testimonials";


function App() {
 
  return (
    <div className="Apps">
      <Router>
        <Navbar />
        <div className="App">
          <Routes>
            
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/testimonial" element={<Testimonial />} />

            {/* <Route path="/career" element={<Career />} />
            <Route path="/career/:id" element={<JobDescription/>} />
            <Route path="/career/:id/apply" element={<Apply/>} /> */}
          </Routes>
        </div>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
