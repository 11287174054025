import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useContext,createContext, useState } from "react";
import {
    getDatabase,
    set,
    ref,
    update,
    child,
    get,
    push,
    query,
    orderByChild,
    equalTo,
    onValue,
  } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC0iYSgq7Zh7bl2GcRfhEDCRhrxsTNclcQ",
  authDomain: "samviddhi.firebaseapp.com",
  databaseURL: "https://samviddhi-default-rtdb.firebaseio.com",
  projectId: "samviddhi",
  storageBucket: "samviddhi.appspot.com",
  messagingSenderId: "582122145104",
  appId: "1:582122145104:web:86e8f53a0f707983d01855",
  measurementId: "G-FW6555YRP0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app)

const FirebaseContext = createContext(null)

export const useFirebase = () => useContext(FirebaseContext)

export const FirebaseProvider = (props) => {

    const [isUserLoggedIn, setIsUserLoggedIn] = useState("")

    // useEffect(() => {
    //     onAuthStateChanged(firebaseAuth, (user0) => {
    //         if (user0) {
    //             const uid = user0.uid;
    //             localStorage.setItem('mySpaceUid', uid)
    //             setIsUserLoggedIn(true)
    //         } else {
    //             setIsUserLoggedIn(false)
    //         }
    //     });
    // }, []);

    const getDataOnce = async (email) => {
        const data = query(
            child(ref(database), "users"),
            orderByChild("email"),
            equalTo(email)
        );
        const snapshot = await get(data);
        let ans = [];
        if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
                ans.push(childSnapshot.val());
            });
        }
        return ans;
    };


    const getUserData = async (key, callback) => {
        const userRef = ref(database, `users/${key}`);
        onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }



    /////////////    method for Hero Section    /////////////
    const getHeroSection = async (callback) => {
        const heroSectionRef = ref(database, `hero_section`);
        onValue(heroSectionRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }



    /////////////    method for services    /////////////
    const getServices = async (callback) => {
        const servicesRef = ref(database, `our_services`);
        onValue(servicesRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }


    const updateServicesField = (serviceId,data) => {
        const serviceRef = ref(
            database,
            `our_services/${serviceId}`
        );
        set(serviceRef, data)
            .then(() => {
                console.log("Status updated successfully!");
            })
            .catch((error) => {
                console.error("Error updating Status:", error);
            });
        return;
    };



    /////////////    method for testimonial    /////////////
    const getTestimonials = async (callback) => {
        const testimonialRef = ref(database, `testimonials`);
        onValue(testimonialRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }



    /////////////    method for why us    /////////////
    const getWhyUs = async (callback) => {
        const whyUsRef = ref(database, `why_us`);
        onValue(whyUsRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }



    /////////////    method for about us    /////////////
    const getAboutUs = async (callback) => {
        const aboutUsRef = ref(database, `about_us`);
        onValue(aboutUsRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }




       /////////////    method for our story    /////////////
       const getOurStory = async (callback) => {
        const aboutUsRef = ref(database, `our_story`);
        onValue(aboutUsRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }



    /////////////    method for contact us    /////////////
    const getContactUs = async (callback) => {
        const contactUsRef = ref(database, `contact_us`);
        onValue(contactUsRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }




    /////////////    method for team    /////////////
    const getTeams = async (callback) => {
        const teamRef = ref(database, `teams`);
        onValue(teamRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }


    const updatePapersData = (key, papersId, data) => {
        const paperRef = ref(
            database,
            `papers/${key}/${papersId}`
        );
        set(paperRef, data)
            .then(() => {
                console.log("Data updated successfully!");
            })
            .catch((error) => {
                console.error("Error updating data:", error);
            });
        return;
    }
    const updatePapersDeleteField = (key, papersId, isDeleted) => {
        const userRef = ref(
            database,
            `papers/${key}/${papersId}/isDeleted`
        );
        set(userRef, isDeleted)
            .then(() => {
                console.log("Status updated successfully!");
            })
            .catch((error) => {
                console.error("Error updating Status:", error);
            });
        return;
    };

    const updatePresentationsData = (key, presentationId, data) => {
        const presentationRef = ref(
            database,
            `presentations/${key}/${presentationId}`
        );
        set(presentationRef, data)
            .then(() => {
                console.log("Data updated successfully!");
            })
            .catch((error) => {
                console.error("Error updating data:", error);
            });
        return;
    }

    const updatePresentationDeleteField = (id, presentationId, isDeleted) => {
        const presentationRef = ref(
            database,
            `presentations/${id}/${presentationId}/isDeleted`
        );
        set(presentationRef, isDeleted)
            .then(() => {
                console.log("Status updated successfully!");
            })
            .catch((error) => {
                console.error("Error updating Status:", error);
            });
        return;
    };

    const refAndKey = (path) => {
        const reff = push(ref(database, `${path}`))
        return [reff, reff.key]
    }

    const pushData = (ref, data) => {
        set(ref, { ...data });
        return ref.key;
    };

    const putData = (key, data) => {
        set(ref(database, key), { ...data });
    };

    // pushData(projectRefAndKey("requests/sample")[0],{data:"data",name:'name',className:'className'});

    // getListOfDevices(localStorage.getItem('uid'))

    let user = isUserLoggedIn ? true : false

    return (
        <FirebaseContext.Provider
            value={{
                putData,
                pushData,
                getDataOnce,
                getUserData,
                updatePapersDeleteField,
                refAndKey,
                updatePresentationDeleteField,
                user,
                updatePapersData,
                updatePresentationsData,
                getServices,
                getTestimonials,
                getWhyUs,
                getAboutUs,
                getContactUs,
                getTeams,
                updateServicesField,
                getOurStory,
                getHeroSection
            }}
        >
            {props.children}
        </FirebaseContext.Provider>
    );
};
