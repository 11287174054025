import React from "react";
import "./Testimonials.css";


const Testimonial = () => (
  <>
   <div className="testimonial-page-section pt-70" id="testimonial-page-content">
    <p className="comming-soon-text">Coming soon...</p>
  </div>
  </>
 
);

export default Testimonial;
