export const SERVICE_JSON = [
  {
    id: 1,
    icon: "mobile-phone.png",
    name: "Data Science",
    icon:"./icon/our_services/app_development.png",
    description:"We empower companies to extract actionable insights from their data through experimentation. Utilizing advanced technologies such as machine learning, artificial intelligence, and deep learning, we cater to diverse analytics requirements with precision and expertise."
  },
  {
    id: 2,
    icon: "machine-learning.png",
    name: "Computer Vision and Image Processing",
    icon:"./icon/our_services/web_app_development.png",
    description:"We provide services to enable systems to identify objects, places, people, actions, and text in images. Our offerings include Image Tagging, Database Creation, Editing & Scaling, 3D Vision Measurements, Edge Processing, and more."
  },
  {
    id: 3,
    icon: "devops.png",
    name: "Geo-Spatial Analysis",
    icon:"./icon/our_services/dev_ops.png",
    description:"Our Geo-Spatial analysts employ advanced statistical methods and machine learning to analyze data, identify patterns, perform spatial analysis, and create predictive models. We offer tailored solutions, including custom applications, data integration, and expert consultation for optimizing geospatial data utilization."

  },
  {
    id: 4,
    icon: "analysis.png",
    name: "Mobile App Development",
    icon:"./icon/our_services/data_science.png",
    description:"We offer end-to-end app development services, from concept finalization to deployment. We ensure platform compatibility, streamline project activities, and optimize costs. Our developers excel in crafting fast, stable, and high-performing mobile apps, adept at API integration and legacy app revamps."

  },
  {
    id: 5,
    icon: "cv.png",
    name: "Web App Development",
    icon:"./icon/our_services/computer_vision.png",
    description:"We specialize in crafting native, cross-platform, and progressive web applications renowned for their unwavering performance, user-centric design, and distinctive value proposition, setting them apart in a competitive landscape."

  },
  {
    id: 6,
    icon: "satellite_black_24dp.svg",
    name: "DevOps Consultancy",
    icon:"./icon/our_services/geo_spatial.png",
    description:"We offer top-tier DevOps consulting, enhancing workflow efficiency through cutting-edge automation and implementation techniques. With extensive hands-on experience, we seamlessly align development and operations teams for optimal collaboration and productivity."

  },
];

export const SERVICE_TITLE_JSON = {
  id: 1,
  title: "Our Services",
  description:
 
    "As a business or an individual, you require great and most-suited IT services that are necessary to keep your\n" +
    "                      business running at peak performance. We offer these great IT services with best and most\n" +
    "                    suitable recent technologies.",
};

export default { SERVICE_TITLE_JSON, SERVICE_JSON };
