import React from "react";
import { useState, useRef, useEffect } from 'react';
import "./Modal.css"
import { NavLink } from "react-router-dom";
import ModalSignIn from './ModalSignIn';

function Modal({ setOpenModal }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = () => {
    const users = JSON.parse(localStorage.getItem('users') || "[]");
    const user = { email:email, password:password };  
    users.unshift(user);

    localStorage.setItem('users',JSON.stringify(users));
    setOpenModal(false);   setModalOpen(true);
  }


  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpenModal(false); 
      }      
    };
    document.addEventListener("mousedown", handler);    

    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

  return (
    <div className="modalBackground">
      <div className="modalContainer" ref={menuRef}>
        <div className="titleCloseBtn">
          <button onClick={() => { setOpenModal(false);}}>X</button>
        </div>
        <div className='signIn'>
          <h2 className='signIn__heading'>Sign In</h2>
          <p className="signIn__paragrap">Use your Google Account</p>
          <form>
              <input className='signIn__input' type="email" name="name" placeholder=" Email" required value={email} onChange={(e) => setEmail(e.target.value)} />            
              <input className='signIn__input' type="password" name="name" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />   
              <NavLink to="/Hello">
                <span className="forgot-password">Forgot Password?</span>
              </NavLink>         
              <input className='signIn__submit' type="button" value="Sign In" onClick={handleClick} />   
              <button className="signUp_btn" onClick={() => { setModalOpen(true);}}>SignUp</button>
          </form>
          {modalOpen && <ModalSignIn setOpenModal={setModalOpen} />}
        </div>
      </div>
    </div>
  );
}

export default Modal;