import { Card, Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from "react";
import TESTIMONIAL_JSON from "../../../../shared/constant/testimonial_data";
import 'bootstrap/dist/css/bootstrap.min.css';



import "./Testimonial.css"
import { useFirebase } from "../../../../firebase/Firebase";
function Testimonial() {

  const [testimonialsData, setTestimonialsData] = useState([])

  const firebase = useFirebase()


  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };



  useEffect(() => {
    firebase.getTestimonials((data) => {
      const arr = []
      for (let testimonialId in data) {
        if (!data[testimonialId].isDeleted) {
          arr.push([{ ...data[testimonialId], testimonialId }])
        }
      }
      setTestimonialsData([...arr])
    })
  }, [])

  return (
    <Row className="testimonial-content p-0 m-0">
      <span className="info-title">Testimonials</span>
      <Col className="px-0 pe-3" md={5} lg={5} sm={12}>
        <Col md={12} sm={12} xs className=" text-justify">
          <span className="info-heading" >We improve the demand for efficiency and quality in entrepreneurship with a creative mind.</span>
        </Col>
      </Col>
      <Col className=" px-0" md={7} lg={7} sm={12} style={{ minHeight: '320px' }} >

        <Carousel controls={false}  interval={2000} pause="hover" className="testimonial-carousel" data-bs-theme="dark">
          {
            testimonialsData.map((item, idx) => {
              return (
                <Carousel.Item key={idx}>
                  <Carousel.Caption>
                    <h5 className="p-0 m-0 testimonial_comment">
                      <span id="quote-first">
                        <img
                          style={{ height: '35px' }}
                          src="icon/quote-first.png"
                        ></img>
                      </span>
                      <span>
                        {item[0].comment}
                        <span id="quote-second">
                          <img
                            style={{ height: '35px' }}
                            src="icon/quote-second.png"
                          ></img>
                        </span>
                      </span>
                    </h5>
                  </Carousel.Caption>
                  <div className="personal-details">
                    <img
                      src={item[0].profile_pic}
                      alt="First slide"
                    />
                    <div className="details">
                      <span id="name">{item[0].name}</span>
                      <span id="designation">{item[0].position} </span>
                    </div>
                  </div>
                </Carousel.Item>
              )
            })
          }


        </Carousel>

      </Col>

    </Row>
  );
}

export default Testimonial;
