import React, { useEffect } from "react";
import "./Navbar.css";
import "../../../App.css";
import { Col, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import Modal from '../modal/Modal';
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavLinkClick = (to) => {
    // navigate(to)
    // const element = document.querySelector(to.slice(1));
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth' });
    // }

  };

  const handleNavLinkClickFromAnotherPage = () => {

  }

  const handleHomeBtnClick = () => {
    const homePageFirstTag = document.getElementById('home-page-content')
    homePageFirstTag.scrollIntoView(true)
  }

  const handleAboutBtnClick = () => {
    const aboutPageFirstTag = document.getElementById('about-us-section')
    aboutPageFirstTag.scrollIntoView(true)
  }


  const handleServiceBtnClick = () => {
    navigate('/')  

    setTimeout(() => {
      const serviceTag = document.getElementById('service');
      serviceTag.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }


  const handleTestimonialBtnClick = () => {
    navigate('/')  

  
    setTimeout(() => {
      const serviceTag = document.getElementById('testimonialk');
      serviceTag.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  const handleContactUsBtnClick = () => {
    navigate('/')  

  
    setTimeout(() => {
      const serviceTag = document.getElementById('contact_us');
      serviceTag.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  // useEffect(()=>{
  //   console.log(window.location.pathname)
  // },[])

  return (
    <div className="border-shadow header col-md-12 py-2">
      <div className="responsive-width px-4">
        <Row className="responsive-md resposive-lg m-0 p-0">
          <Col xs sm={4} md={4} className="cursor-pointer d-flex align-items-center p-0">

            <NavLink to="/" onClick={handleHomeBtnClick}>
              <img alt="logo" style={{ width: "50%" }} src={"./icon/lotion-10124.png"} />

            </NavLink>

          </Col>
          <Col xs sm={8} md={8} className="text-overflow align-self-center p-0 nav-content">
            <Nav className="display-flex justify-content-between align-items-center">
              <Nav.Item className="">
                <NavLink className={window.location.pathname === "/" ? "selected p-0 link" : "p-0 link"} to="/" onClick={handleHomeBtnClick} >
                  Home
                </NavLink>
              </Nav.Item>

              <Nav.Item className="">
                <NavLink className={window.location.pathname === "/about" ? "selected p-0 link" : "p-0 link"} to="/about" onClick={handleAboutBtnClick}>
                  About
                </NavLink>
              </Nav.Item>

              <Nav.Item className="" >
                <Nav.Link href="/#service" className={window.location.hash === "#service" ? "selected p-0 link" : "p-0 link"}   >
                  Services
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link href="/#testimonial" className={window.location.hash === "#testimonial" ? "selected p-0 link" : "p-0 link"}   >
                  Testimonials
                </Nav.Link>
              </Nav.Item>

              <div>
                <Nav.Item className="">
                  <Nav.Link className="p-0 link" to="/#contact_us" href="/#contact_us">
                    <button id="contact-btn">Contact Us</button>
                  </Nav.Link>
                </Nav.Item>
              </div>


            </Nav>
          </Col>
        </Row>

        <Row className="responsive-sm">
          <Col xs={9} sm={9} className="cursor-pointer d-flex align-items-center">
            <NavLink to="/" onClick={handleHomeBtnClick}>
              <img alt="logo" style={{ width: "50%", minWidth: '150px' }} src={"./icon/lotion-10124.png"} />
            </NavLink>

            {/* <div className="samviddhi-text">
              <span className="samviddhi ml-2">SamvidDhi</span>
              <span className="samviddhi_logo_text">Think, imagine and accelerate exponentially..</span>
            </div> */}
          </Col>
          <Col xs={3} sm={3} className="text-align-end p-0 " style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Dropdown >
              <Dropdown.Toggle id="dropdown-autoclose-true">
                <img className="menu-icon" src={"../icon/menu.png"} alt="Menu" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className={window.location.pathname === "/" ? "selected" : ""} href="/">Home</Dropdown.Item>
                <Dropdown.Item className={window.location.pathname === "/about" ? "selected" : ""} href="/about">About</Dropdown.Item>
                <Dropdown.Item onClick={handleServiceBtnClick} className={window.location.hash === "#service" ? "selected" : ""} href="/#service">Services</Dropdown.Item>
                <Dropdown.Item onClick={handleTestimonialBtnClick} className={window.location.hash === "#testimonial" ? "selected" : ""} href="/#testimonial">Testimonials</Dropdown.Item>
                <Dropdown.Item onClick={handleContactUsBtnClick} className={window.location.hash === "#contact_us" ? "selected" : ""} href="/#contact_us">Contact Us</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      {/* {modalOpen && <Modal setOpenModal={setModalOpen} />} */}
    </div>
  )
};

export default Navbar;
