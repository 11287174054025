import React from "react";
import "./About.css";
import WorkSection from "./section/work-section/Work-section";
import AboutSamviddhi from "./section/about-samviddhi/About_samviddhi";
import AboutInfo from "./section/about-info/About_info";
import Team from "./section/team/Team";
import Contact from "../home/section/contactUs/Contact";

function About() {
  return (
    <div className="about-us-section pt-70" id="about-us-section">
      <div className="info-section px-5" >
        <AboutInfo />
      </div>
      <div className="about-samviddhi-section px-5">
        <AboutSamviddhi />
      </div>
      <div className="work-section px-5">
        <WorkSection />
      </div>
      <div className="team-section px-5" id="team_section">
        <Team />
      </div>
    </div>
  );
}

export default About;
