import React from "react";
import "./Service.css";


const Service = () => (
  <>
   <div className="service-page-section pt-70" id="service-page-content">
    <p className="comming-soon-text">Coming soon...</p>
  </div>
  </>
 
);

export default Service;
